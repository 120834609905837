.icon-delete-product {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

#add_or_edit_product {
  .table-responsive {
    max-height: 360px;
    overflow-y: auto;
  }
}

.input-quatity-product {
  input.form-control, select.form-control {
    height: 33px !important;
    font-size: 14px !important;
    text-align: center;
  }
}

.sum-product-export {
  display: flex;
  justify-content: end;

  .sum-product-export-title {
    padding-left: 345px;
    width: 50%;
  }

  .sum-product-export-number {
    padding-left: 23px;
    text-align: center;
    width: 50%;
  }

}


.button-export-batch {
  width: 130px;
  height: 40px;
  border: none;
  background: #F69320;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #d77a10;
  }
}

.button-close-export-batch {
  width: 130px;
  height: 40px;
  border: none;
  background: #F9F9F9;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #181818;
  text-decoration: none;

  &:hover {
    color: #181818;

    background: #d9dcdc;
  }
}

.icon_add_item_export_product {
  color: rgba(0, 149, 61, 1);
  margin-top: 3px;
}