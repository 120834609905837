.dashboard {
  width: 100%;
  padding: 38px;

  .labors {
    width: 340px;
    height: 375px;
    padding: 20px 30px;
    margin-right: 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.15);
    border-radius: 15px;

    .title {
      margin-bottom: 16px;

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 135%;
        color: #5C5C5C;
      }
    }

    .count_labor {
      width: 92px;
      height: 56px;
      z-index: 1000;
      top: 85px;

      p {
        font-weight: 600;
        font-size: 30px;
        width: 100%;
        text-align: center;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 8px;
      }

      span {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }

    .count_user {
      .line {
        width: 60px;
        height: 20px;
        display: inline-block;
        background-color: white;
        position: absolute;
        bottom: 72px;
        z-index: 1200;
      }

      .info_user {
        width: 100%;
        height: 55px;
        margin-top: 16px;
        padding: 0 25px;
        position: relative;

        .user_1 {
          width: 230px;
          bottom: 0;
          position: absolute;
          z-index: 1005;
        }

        .user_ {
          height: 20px;
          .box{
            width: 19px;
            height: 19px;
            margin-right: 8px;
            display: block;
            background: #008100;
            border-radius: 3px;
          }
          .box.warehouse{
            background: #38B138;
          }
          .box.pack{
            background: #93C82A;
          }
          .box.manage{
            background: #F69320;
          }
          span {
            display: inline-block;
            width: 60px;
            font-weight: 400;
            font-size: 14px;
            color: #00953D;
          }
        }
      }
    }

  }

  .setups {
    width: 994px;
    height: 258px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item {
    width: 315px;
    height: 120px;
    display: flex;
    margin: 0 24px 19px 0;
    background: #FFFFFF;
    padding: 18px 28px;
    box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.15);
    border-radius: 15px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      margin-right: 26px;
      width: 48px;
      height: 48px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 135%;
      margin-bottom: 20px;
      color: #5C5C5C;

    }

    span {
      font-weight: 600;
      font-size: 32px;
      letter-spacing: -1px;
      color: #000000;
    }
  }

}


@media screen and (max-width: 1600px){
  .dashboard{

  }
}