.max-width-1008 {
  max-width: 1008px !important;

  .export-batch-select {
    width: 260px;
    margin-right: 10px;
  }

  .batch-name {
    color: rgba(0, 148, 68, 1);
    font-weight: 700;
    font-size: 14px;
    margin: 0 15px 0 15px;
  }

  .supplies-exported {
    margin: 0 15px 15px 15px;

  }
}


#export_batch_product {
  .table-responsive {
    max-height: 360px;
    overflow-y: auto;
  }
}

.input-quatity-product {
  input.form-control, select.form-control {
    height: 33px !important;
    font-size: 14px !important;
    text-align: center;
  }
}

.button-export-batch {
  width: 130px;
  height: 40px;
  border: none;
  background: #F69320;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #d77a10;
  }
}

.button-close-export-batch {
  width: 130px;
  height: 40px;
  border: none;
  background: #F9F9F9;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #181818;
  text-decoration: none;

  &:hover {
    color: #181818;

    background: #d9dcdc;
  }
}

.icon_add_item_export_batch {
  color: rgba(246, 147, 32, 1);
}
