.batch_supplies{

  .tab_header {
    width: 100%;
    height: 72px;

    .tab {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #FFFFFF;
      border: 1px solid #D7D7D7;

      span {
        color: #009444;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
      }
    }

    .tab.active {
      background: #009444;
      border: 1px solid #009444;

      span {
        color: white;
      }
    }

  }

  .h-72{
    height: 78px;
  }

}