.view-detail-manufacturing {
  border: 1px solid rgba(215, 215, 215, 1);
  outline: none;
  border-radius: 5px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 38px;
  color: rgba(0, 149, 61, 1);

  &:hover {
    background-color: #f1ebeb;
  }

  &:focus {
    border: none;
    outline: none !important;
  }
}


.change-status-job {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
}

#inputPreview {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.change-status-job + label {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: rgb(0, 0, 0);
}

.change-status-job + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: 26px;
  height: 26px;
  background-color: white;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 3px;
  box-shadow: none;
}

.change-status-job:checked + label::after {
  content: " ";
  background-image: url("Vector.svg");
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  left: 0;
  top: -3px;
  text-align: center;
  background-color: transparent;
  font-size: 10px;
  height: 26px;
  width: 26px;
  border: 1px solid rgba(0, 149, 61, 1);
  border-radius: 3px;
}