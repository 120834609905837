#qr_code_tracking {
  .form{
    width: 100%;
    padding: 12px 28px 20px;
    background: #F8FDFF;
    .step_2 {
      width: 100%;
      .link_download_qr {
        width: 100%;
        padding: 10px 20px;
        margin: 8px 0 16px;
        border: 1px solid rgba(0, 149, 61, 0.46);
        .action_{
          input {
            width: 89%;
            height: 28px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }
          button{
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}
#history_qr{

  .form{
    width: 100%;
    display: inline-block;
    padding: 12px 28px 20px;
    background: #F8FDFF;
    .list_history {
      width: 100%;
      height: 312px;
      overflow-y: scroll;
      padding: 10px 20px;
      margin: 8px 0 16px;
      border: 1px solid rgba(0, 149, 61, 0.46);

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #7C7B7B;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .isExpired{
        opacity: 0.4;
      }

      .item{
        width: 100%;
        height: 58px;
        margin-bottom: 16px;
        span{
          font-size: 12px;
        }
        .action_{
          margin-top: 6px;
          input {

            width: 89%;
            height: 28px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 12px;
            margin-right: 12px;
            border: 1px solid #7C7B7B;
            border-radius: 2px;
          }
          button{
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

#add_edit_tracking_qr {
  .modal-dialog {
    max-width: 905px;
  }

  .content_access {
    width: 100%;
    background: #F8FDFF;
    padding: 20px 14px;

    .barcode {
      width: 266px;
      height: 152px;
      display: flex;
      margin-right: 32px;
      margin-bottom: 5px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #009444;
      padding: 12px 18px;
    }

    .input_barcode {
      width: 266px;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      border: 1px solid #1A1A1A;
      border-radius: 0;

      &::placeholder {
        color: #D7D7D7;
      }
    }

    .no_barcode {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #DCDCDC;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
      }
    }

    .QR_code {
      width: 230px;
      height: 230px;
      display: flex;
      margin-right: 32px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #009444;
    }

    .form_access {

      .list_info_product {
        width: 100%;
        height: 335px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .info {
          width: 100%;

          .title_top {
            width: 100%;
            height: 38px;
            padding: 0 12px;
            display: flex;
            margin-bottom: 12px;
            align-items: center;
            justify-content: space-between;
            background: #009444;

            span {
              font-weight: 500;
              font-size: 14px;
              text-align: justify;
              text-transform: uppercase;
              color: #FFFFFF;
            }

            i {
              color: white;
              font-size: 18px;
            }
          }

          .list_content {
            width: 100%;

            .item {
              width: 100%;
              margin-bottom: 12px;
              display: flex;
              align-items: center;

              i {
                font-size: 18px;
              }

              input, label {
                height: 32px;
                padding: 0 16px;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
                margin-right: 12px;
                display: flex;
                align-items: center;
                border: 1px solid #1A1A1A;
                border-radius: 2px;
              }

              .time_range {
                height: 32px !important;

                i {
                  top: 7px;
                }
              }
            }

            textarea {
              width: 100%;
              padding: 8px 16px;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              border: 1px solid #1A1A1A;
              border-radius: 2px;
            }
          }
        }
      }

      .save_qr_code {
        width: 100%;
        height: 48px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid #000000;
        margin: 14px 0 12px;

        .action_barcode {
          width: 105px;
          height: 28px;
          background-color: transparent;
          border: 1px solid #009444;
          border-radius: 2px;
          font-weight: 500;
          font-size: 14px;
          text-transform: capitalize;
          color: #009444;

          .up_img {
            width: 98px;
            height: 28px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
      }
    }
  }

}

#update_tracking {
  .form_access {
    width: 525px;
    .list_info_product .info .list_content {
      .item {
        input, label {
          width: 45%;
        }
        .time_range {
          width: 45%;
        }
      }

    }
  }
}

#add_edit_tracking_qr {
  .form_access {
    width: 575px;
    .list_info_product .info .list_content {
      .item {
        input, label {
          width: 50%;
        }

        label.title, input.title {
          width: 40%;
        }

        .time_range {
          width: 50%;
        }
      }

    }
  }
}
