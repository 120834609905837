.button-export-product-procedureid {
  border: 2px solid rgba(0, 149, 61, 1);
  outline: none;
  color: rgba(0, 149, 61, 1);
  font-weight: 600;
  background-color: transparent;
  border-radius: 3px;
  margin-right: 10px;

  &:hover {
    color: rgba(0, 149, 61, 1);
  }

  .icon-export-product {
    margin-right: 10px;
  }
}