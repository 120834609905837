.detail_plan_production {
  font-weight: 500;
}

.modal_full .modal-dialog .modal-content {
  padding: 0 20px !important;
}

.completed-status {
  color: rgba(0, 149, 61, 1);
}

.cancel-status {
  color: rgba(230, 10, 50, 1);
}

.pending-status {
  color: rgba(230, 10, 50, 1);
}
