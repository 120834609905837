.info_access{
  padding: 22px 95px 32px;
  .btn_print{
    border: 1px solid #00953D;
    color: #00953D;
  }
  .logo{
    margin-bottom: 32px;
  }
  .container{
    max-width: 1239px !important;
  }
  h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #00953D;
    margin-bottom: 16px;
  }

  .trackingImage{
    display: flex;
    justify-content: flex-end;
    img{
      width: 516px;
      height: 305px;
      object-fit: cover;
      border: 1px solid #00953D;
      border-radius: 3px;
    }
  }

  .info_unit{
    width: 100%;
    margin-bottom: 45px;

    .info_text{
      width: 100%;
      min-height: 38px;
      padding: 8px 16px;
      display: flex;
      border-radius: 8px;
      label{
        margin: 0;
        width: 172px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        height: 18px;
        color: #515154;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      span{
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
        width: 396px;
      }
      &:first-child, &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9), &:nth-child(11){
        background: #F5F5F7;
      }
    }
  }

  .scroll_images{
    width: 100%;
    height: 280px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }


    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_images{
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      .item{
        width: 254px;
        height: 260px;
        border: 1px solid #57902B;
        border-radius: 3px;
        margin-right: 16px;
        img{
          width: 252px;
          height: 258px;
          object-fit: cover;
        }
      }
    }
  }


  .scroll_certificate{
    width: 100%;
    height: 280px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 100%;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    .list_certificate{
      width: 100%;
      max-width: 2600px;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      .item{
        width: 254px;
        height: 260px;
        margin-right: 24px;
        border: 1px solid #57902B;
        border-radius: 3px;
        background-color: white;
        img{
          width: 252px;
          height: 258px;
          object-fit: cover;
        }
      }
    }
  }

}

@media print {
  body * {
    visibility: hidden;
  }
  .info_access * {
    visibility: visible;
  }
}

@media screen and (max-width: 1024px){
  .info_access {
    .info_{
      width: 100%;
      display: inline-block !important;
      .info_unit{
        padding: 0 !important;
        width: 100% !important;
      }
      .trackingImage{
        padding: 0 !important;
        width: 100% !important;
        justify-content: center;
        margin-bottom: 45px;
        img{
          width: 100%;
          height: 240px;
        }
      }
    }
  }

}

@media screen and (max-width: 540px){
  .info_access {
    padding: 32px 25px;

    .info_unit .info_text{
      align-items: start;
      padding: 8px 12px;
      span {
        width: 230px;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .list_images, .list_certificate{
      width: 1350px;
      .item {
        width: 48%;
        height: 180px;
      }
    }

  }
}


@media screen and (max-width: 414px){
  .info_access {
    padding: 24px 6px;
    .logo img{
      width: 100px;
    }
    h2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
    }

    .info_unit .info_text {
      label {
        width: 146px;
        padding-right: 18px;
      }
      span {
        width: 196px;
      }
    }
  }

}