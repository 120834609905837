#RFS-StepButton.active {
  background-color: transparent !important;
}

#RFS-StepButton {
  background-color: transparent !important;
  color: darkgrey !important;
}

.button-add-plan-order-work {
  width: 130px;
  height: 40px;
  border: none;
  background: #29AB56;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #21984A;
  }
}
