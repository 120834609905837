.filter_header {
  height: 72px;
  width: 100%;
  padding: 0 16px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00953D;
  }

  img {
    margin-right: 26px;
  }

  .form_search {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #009444;
      display: inline-block;
      margin-right: 12px;
    }

    .filter_date, .filter_select, .filter_search {
      width: 189px;
      height: 36px;

      input, select {
        width: 100%;
        height: 100%;
        padding: 0 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        border-radius: 2px;
        border: none;
        outline: none;
        border-bottom: 2px solid #009444 !important;
        background-color: rgba(255, 255, 255, 1);
      }

      i {
        top: 12px;
        right: 12px;
      }
    }

    .filter_search input {
      padding: 0 35px 0 12px;
    }
  }
}