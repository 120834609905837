.body-wrapper {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 280px);
  background: #F8FDFF;
  padding-bottom: 120px;

  .main-content {
    width: 100%;
    padding-left: 8px;
  }

}

.cursor_pointer {
  cursor: pointer;
}

.send-request-outline {
  width: 246px;
  height: 50px;
  background: transparent !important;
  border-radius: 2px;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #29AB56;
  text-decoration: none;
  border: 1px solid #29AB56;

  &:hover {
    color: #29AB56;
    background: transparent;
  }

  &:disabled {
    opacity: 0.6;
  }
}


.table td .action,
.MuiTable-root td .action {
  display: flex;
  align-items: center;
  justify-content: center;

  button.bt_action {
    border: none !important;
    background-color: transparent !important;
    padding: 6px !important;

    &:focus-visible,
    &:focus {
      outline: none !important;
    }

    a {
      display: inline-block !important;
      margin-right: 2px !important;
    }

    img {
      width: 24px !important;
      height: 24px !important;
      border-radius: 0 !important;
    }
  }
}

.table td,
.table th {
  vertical-align: middle;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}


.scroll_file {
  width: 100%;
  height: 600px;
  padding-bottom: 40px;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #7C7B7B;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.react-pdf__Document {
  position: absolute;
  top: 0;
}

$grid-breakpoints-lg: 992px !default;

.custom-scrollbar {
  @media (min-width: $grid-breakpoints-lg) {
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }
  }
}

.icon-action {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.table-responsive {
  overflow-x: inherit !important;
}

select {
  background-color: transparent !important;
}

input.form-control,
select.form-control {
  width: 100%;
  height: 48px !important;
  font-size: 14px !important;
  padding: 0 16px;
  color: #000000;

  &::placeholder {
    color: #858585;
  }
}

.input-height-33 {

  input.form-control,
  select.form-control {
    height: 33px !important;
    font-size: 14px !important;
    text-align: center;
  }
}

.bt_remove_outline {
  color: red !important;
  border: 1px solid red !important;
}

button:disabled {
  cursor: no-drop;
  opacity: 0.4;
}

.time_range {
  width: 100%;
  height: 48px !important;
  font-size: 14px !important;

  input {
    width: 100%;
    font-size: 14px !important;
    cursor: pointer;
    padding-left: 12px;
    margin-left: 5px;
    border: 1px solid #f3f3f3;
  }

  i {
    top: 18px;
    right: 16px;
    position: absolute;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;

  .react-datepicker__input-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;

    input {
      padding: 0 16px;
      font-size: 14px;
      width: 100% !important;
      margin: 0;
      height: 100%;
      background-color: transparent;

      &:disabled {
        background-color: #e9ecef;
        border-radius: 0.3rem;
        border: 1px solid #d0d1d3;
      }
    }
  }

}

.react-datepicker-popper {
  z-index: 1200 !important;
}

.bt_map {
  width: 136px;
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00953D;
  border-radius: 2px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;

  img {
    margin-right: 6px;
  }

  &:hover {
    color: white;
  }
}

.date_error input {
  border: 1px solid red !important;
}

table tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

table tbody tr:nth-of-type(even) {
  background-color: #F3F2F8;
}


textarea.form-control {
  width: 100%;
  font-size: 14px !important;
  padding-left: 16px;
  padding-right: 16px;
  color: #000000;

  &::placeholder {
    color: #858585;
  }
}


.error {
  font-size: 13px;
  color: red;
  display: inline-block;
  width: 100%;
  height: 20px;
  margin-bottom: 0;
  padding-top: 4px;
}

.border_error {
  border: 1px solid red !important;

  &:focus-visible,
  &:focus {
    outline: none !important;
  }
}


.text-success {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #009444;
}

.color-green {
  color: #009444 !important;
}

.color-red {
  color: rgba(255, 48, 48, 1) !important;
}

.w-ratio {
  width: 228px !important;
}

.input-password {
  i {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #000000;
    font-size: 18px;
    z-index: 1000;
  }
}


.form-group {
  height: 102px;
  margin-bottom: 0 !important;
}

#popup_detail_job {
  .modal-dialog {
    max-width: 1172px;

    .detail_plan {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 44px;

      &:first-child span {
        border-top: 1px solid #D9D9D9;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        width: 189px;
        min-height: 44px;
        background: #ECF0FF;
        border-bottom: 1px solid white;
      }

      span {
        width: 930px;
        min-height: 44px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 0 16px;
        border-bottom: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
      }
    }
  }
}

.bg-green {
  background-color: #009444 !important;
}

.bt-outline-action {
  max-width: 85px;
  min-width: 56px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #1E9A57;
  padding: 0 8px;
  background: #FFFFFF;
  border: 1.5px solid #1E9A57;
  border-radius: 4px;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.bt-action-delete {
  max-width: 85px;
  min-width: 56px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ED2121;
  background: #FFFFFF;
  border: 1.5px solid #ED2121;
  border-radius: 4px;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.bt-back-outline {
  width: 354px;
  height: 50px;
  border: 1px solid #29AB56;
  background: transparent;
  border-radius: 2px;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #29AB56;
  margin: 0;
  text-decoration: none;
}

.send-request {
  width: 250px;
  height: 50px;
  border: none;
  background: #29AB56;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #21984A;
  }
}

.bt_global {
  width: 160px;
  height: 38px;
  font-size: 14px;
  font-weight: 500;

  i {
    font-size: 20px;
  }

  &:focus, &:focus-visible {
    outline: none;
  }
}

.modal {
  .modal-dialog {
    max-width: 798px;
  }

  label {
    font-weight: 500;
  }

  .form-group {
    height: 102px;
  }

  .modal-header {
    border: none;

    h5 {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      color: #333333;
    }

    .close {
      opacity: 1 !important;

      span {
        font-size: 26px !important;
        font-weight: 400;
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 12px 18px 32px !important;
  }


}

.select-align-left {
  text-align: start !important;
}

.pagination .page-item .page-link {
  border-color: #E0E0E0;
}

.pagination .break-me {
  padding: 0.2rem 0.75rem 0.5rem;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}


.pagination .page-item.active .page-link {
  background: #29AB56;
  border-color: #29AB56;
}

.page-link:hover {
  background: #29AB56 !important;
  border-color: #29AB56 !important;
}

table th {
  padding: 1.5rem 0.9375rem !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  background: #ECF0FF;
  border-right: 1px solid white;

  &:last-child {
    border-right: none;
  }
}


table td {
  line-height: 22px !important;
  max-width: 350px;
  white-space: normal !important;

  p.des {
    width: 100%;
    height: 40px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}


.table_land {
  padding: 0;
}

.bt_add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px;
}

.button_add {
  width: auto;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00953D;
  border-radius: 2px;
  border: none;

  i {
    color: white;
    font-size: 18px;
    margin-right: 6px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
}

.button_global {
  width: 300px;
  height: 42px;
  padding: 3px 14px;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(0, 148, 68, 1);
  border-radius: 4px;
  border: 2px solid rgba(0, 148, 68, 1);;

  .title-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 148, 68, 1) !important;
    margin-left: 16px;
  }
}

.button_warning {
  width: auto;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(246, 147, 32, 1);
  border-radius: 2px;
  border: none;

  i {
    color: white;
    font-size: 18px;
    margin-right: 6px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
}


#popup_success {
  .modal-dialog {
    width: 408px;
    height: 196px;
    background: #FFFFFF;
    border-radius: 8px;

    .modal-content {
      border: none;

      .modal-header {
        padding: 19px 19px 0 !important;
      }

      .icon {
        margin-bottom: 24px;
      }

      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #29AB56;
        margin-bottom: 0;
      }
    }
  }
}

#popup_confirm,
#popup_confirm_comment {
  .modal-dialog {
    width: 600px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;

    .modal-content {
      border: none;

      .modal-header {
        padding: 19px 19px 0 !important;
      }

      .icon {
        margin-bottom: 24px;
      }

      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      .action {
        margin-bottom: 32px;

        button {
          width: 264px;
          height: 44px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 2px;
          margin: 0 12px;
        }

        button.btn-cancel {
          border: 1.5px solid #858585;
          color: #858585;
          background-color: transparent;
        }

        button.btn-confirm {
          color: white;
          background: #29AB56;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }


}

.header_back {
  width: 100%;
  height: 72px;
  padding: 22px 16px;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #009444;
    margin-right: 6px;
    display: inline-block;
  }

  i {
    color: #009444;
    margin-right: 6px;
    font-size: 18px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #009444;
  }

  a.back {
    display: flex;

    i {
      margin-right: 8px;
      color: #009444;
      font-size: 20px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #009444;
    }
  }
}

a.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #009444;
}

.btn-action {
  width: 38px;
  height: 38px;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: 1px solid #D7D7D7;
  border-radius: 3px;

  span {
    color: #acacac;
    font-size: 28px;
  }

  &:hover .list_content {
    opacity: 1;
    display: block;
    animation-duration: 0.5s;
    animation-name: showEffect;
  }

  .list_content {
    position: absolute;
    z-index: 1000;
    top: 36px;
    padding-top: 10px;
    right: -24px;
    opacity: 0;
    display: none;
    width: 140px;
    height: auto;

    .action {
      width: 140px;
      height: auto;
      background: #F5F5F5;
      border-radius: 3px 3px 0px 0px;
      transition: 0.5s;
      box-shadow: 0px 0px 15px rgba(12, 47, 76, 0.15);

      &::before {
        border-width: 14px;
        border-style: solid;
        border-color: transparent transparent rgb(255, 255, 255);
        border-image: initial;
        margin-left: 15px;
        top: -16px;
        left: 50%;
        content: "";
        height: 0px;
        width: 0px;
        position: absolute;
        pointer-events: none;
      }

      &:last-child {
        button.bt {
          border: none;
        }
      }

      button.bt {
        max-width: 140px;
        width: 140px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        border: none;
        text-decoration: none;
        display: flex;
        border-radius: 0;
        align-items: center;
        padding: 10px 24px 10px 16px;
        border-bottom: 1px solid #eeeeee;

        &:hover {
          background: #F7F8FF;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}

@keyframes showEffect {
  from {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: block;
  }
}

.filter_header {
  width: 100%;
  height: 72px;
  padding: 0 16px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00953D;
  }

  img {
    margin-right: 26px;
  }

  .form_search {
    margin-right: 24px;
    height: 36px;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #009444;
      display: inline-block;
      margin-right: 12px;
    }

    .filter_date,
    .filter_select,
    .filter_search {
      width: 189px;
      height: 36px;

      .input-group {
        height: 100%;
      }

      input,
      select {
        width: 100%;
        height: 100% !important;
        padding: 0 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        border-radius: 2px;
        border: none;
        outline: none;
        border-bottom: 2px solid #009444 !important;
        background-color: rgba(255, 255, 255, 1);
      }

      i {
        top: 12px;
        right: 12px;
      }
    }

    .filter_search input {
      padding: 0 35px 0 12px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.basic-multi-select {

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 48px !important;
  }

  .css-1jqq78o-placeholder {
    font-size: 14px;
  }
}

select {
  &:disabled {
    background-color: #e9ecef !important;
  }
}

.canvasjs-chart-credit,
.highcharts-credits,
.highcharts-axis {
  display: none !important;
}

.modal.popupZoom {
  .close_ {
    width: 44px;
    height: 44px;
    padding: 10px;
    border: 0;
    right: -150px;
    top: 40px;
    z-index: 1200;
    cursor: pointer !important;
    border-radius: 4px;
    position: absolute;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(30, 30, 30, .6);

    &:hover {
      cursor: pointer !important;
    }

    i {
      color: #FFFFFF;
      font-size: 32px;
      cursor: pointer;
    }
  }

  .modal-dialog {
    max-width: 1250px;
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;

    .modal-content {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      background-color: transparent;

      .carousel {
        width: 100%;
        height: 100%;

        .carousel-inner {
          width: 100%;
          height: 100%;
          border-radius: 6px;

          .carousel-item {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              cursor: zoom-in;
              border-radius: 6px;
              transition: transform .3s;
              object-fit: scale-down;
              transform: scale(1);
            }

            img.zoomOut {
              cursor: zoom-out;
              transform: scale(1.4);
            }
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 48%;

          i {
            color: #FFFFFF;
            font-size: 50px;
          }

          &:hover {
            i {
              color: #00953D;
            }
          }
        }

        .carousel-control-prev {
          left: -150px;
        }

        .carousel-control-next {
          right: -150px;
        }

      }
    }
  }
}

.h-48 {
  height: 48px;
}

.modal_full {
  padding: 0 !important;

  .modal-dialog {
    margin: 0 !important;
    padding: 32px 0;
    max-width: 100%;
    height: 100vh;
    background-color: white;

    .modal-content {
      padding: 0 168px;
    }

    button.close {
      width: 92px;
      height: 32px;
      right: 16px;
      top: -18px;
      justify-content: center;
      position: absolute;
      background: #F4F4F4;
      border-radius: 4px;
      opacity: 1;
      z-index: 1060;

      span {
        line-height: 14px;
        cursor: pointer;
      }

      span,
      p {
        height: 20px;
        color: #000000;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        padding-left: 5px;
      }
    }

    .modal-body {
      label {
        display: inline-block;
        width: 256px;
        margin-bottom: 0;
      }

      .input-group, .time_range {
        width: calc(100% - 256px) !important;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }
  }

  .item_info {
    align-items: center;
    background-color: white;
    border-bottom: 8px solid #f8f8f8;
  }

}


@media screen and (max-width: 1600px) {
  .body-wrapper {
    width: calc(100% - 260px);
  }
}


@media screen and (max-width: 1500px) {
  .body-wrapper {
    width: calc(100% - 250px);
  }
}