.button-detail-supplies {
  padding: 12px 30px;
  border-radius: 3px;
  border: none;
  outline: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: white;
  }

  &-title {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
  }
}

.btn-export-supplies {
  background-color: rgba(0, 149, 61, 1);
}

.btn-import-supplies {
  background-color: rgba(246, 147, 32, 1);
}

