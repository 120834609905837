#updateAccess {
  .modal-dialog {
    max-width: 868px;
  }

  .content_access {
    width: 100%;
    background: #EFFFEF;
    padding: 20px 14px;

    .QR_code {
      width: 231px;
      height: 231px;
      display: flex;
      margin-right: 32px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #009444;
    }

    .form_access {
      width: 522px;

      .list_info_product {
        width: 100%;
        height: 335px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .info {
          width: 100%;

          .title_top {
            width: 100%;
            height: 38px;
            padding: 0 12px;
            display: flex;
            margin-bottom: 12px;
            align-items: center;
            justify-content: space-between;
            background: #009444;

            span {
              font-weight: 500;
              font-size: 14px;
              text-align: justify;
              text-transform: uppercase;
              color: #FFFFFF;
            }

            i {
              color: white;
              font-size: 18px;
            }
          }

          .list_content {
            width: 100%;

            .item {
              width: 100%;
              margin-bottom: 12px;

              i {
                font-size: 18px;
              }

              input {
                width: 45%;
                height: 32px;
                padding: 0 16px;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                margin-right: 12px;
                border: 1px solid #1A1A1A;
                border-radius: 2px;
              }
            }

            textarea {
              width: 100%;
              padding: 8px 16px;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              border: 1px solid #1A1A1A;
              border-radius: 2px;
            }
          }
        }
      }

      .save_qr_code {
        width: 100%;
        height: 65px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #000000;
        margin: 14px 0 12px;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          margin-right: 8px;
          color: #009444;
        }

        span.input {
          width: 91px;
          height: 32px;
          margin-right: 4px;
          border: 1px solid #1A1A1A;
          border-radius: 2px;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000000;
        }

        button {
          width: 98px;
          height: 32px;
          border: none;
          font-weight: 700;
          font-size: 14px;
          text-transform: capitalize;
          color: #FFFFFF;
          background: #9F9F9F;
          border-radius: 2px;
        }
      }
    }
  }

}

#upload_images_access {
  .images_product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 41px;

    .images {
      width: 354px;
      height: 208px;
      position: relative;
      border: 1px solid #00953D;
      border-radius: 3px;

      .img_ {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .no_image {
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 65px;
        justify-content: center;
        background: linear-gradient(0deg, #EFEFEF, #EFEFEF);

        img {
          width: 32px;
          height: 32px;
        }
      }

      .upload {
        width: 352px;
        height: 54px;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer !important;
        background: rgba(255, 255, 255, 0.75);

        input {
          width: 352px;
          height: 54px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 352px;
            height: 54px;
            opacity: 0;
            left: 0;
            border-radius: 0 0 80px 80px;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }

        span {
          font-weight: 500;
          font-size: 16px;
          text-align: justify;
          color: #00953D;
        }

        img {
          margin-right: 12px;
        }
      }
    }
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
    color: #333333;
  }

  .list_image {
    width: 100%;
    height: 205px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .list_{
      min-width: 1250px;
      width: 100%;
      height: 194px;
      overflow: hidden;
      display: flex;
      align-items: center;
      .upload{
        width: 170px;
        height: 174px;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 0.673077px solid #00953D;
        border-radius: 2px;
        input {
          width: 170px;
          height: 174px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 170px;
            height: 174px;
            opacity: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }
      }

      .item{
        width: 170px;
        height: 174px;
        margin-right: 16px;
        position: relative;
        border: 0.673077px solid #00953D;
        border-radius: 2px;
        .img{
          width: 168px;
          height: 172px;
          object-fit: cover;
        }
        .close_item{
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
}