.barcode-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding-left: 260px;

  .barcode-display-content {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 150px;

    .barcode {
      width: 100%;
      height: 100%;
      border: 1px dashed rgba(0, 149, 61, 1);
      display: flex;
      justify-content: center;
      align-items: center;

      .barcode-content {
        width: 80%;
        height: 80%;
        background-color: rgba(223, 223, 223, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        .no_barcode {
          padding: 20px 50px;
          text-align: center;

          .title {
            color: rgba(77, 77, 77, 1);
            font-weight: 400;
          }
        }
      }
    }

    .input_barcode {
      border-bottom: 1px solid rgba(215, 215, 215, 1);
      outline: none;
    }
  }
}

