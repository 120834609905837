#popup_confirm_complete_manufacturing {
  .modal-dialog {
    width: 615px;
  }

  .btn-confirm {
    background-color: rgba(0, 148, 68, 1);
    color: white;
    height: 47px;

    &:hover {
      background-color: rgb(3, 107, 51);
    }
  }
}