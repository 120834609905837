.infor-detail-product-container {
  padding: 0 10px 0 14px;
}

.infor-detail-product-container .header_back {
  padding: 15px 3px;
  height: 22px;
}

.invetory-detail-product .filter_header {
  flex-direction: row-reverse;
  height: 60px;
}

.edit_product_container {
  color: rgba(0, 148, 68, 1);
  font-size: 16px;
  display: flex;
  margin-left: 10px;
  cursor: pointer;

  .back-product {
    font-size: 16px;
  }

  .edit_product_button {
    background-color: transparent;
    color: rgba(0, 148, 68, 1);
    border: none;
    outline: none;
  }
}