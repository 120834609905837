.header-top {
  width: 100%;
  height: 98px;
  background: #FFFFFF;
  position: relative;

  .content-header {
    width: 100%;
    height: 98px;
    padding: 0 24px;
    background-color: white;

    h4 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-bottom: 0;
    }

    .info_farm {
      display: flex;
      margin-right: 34px;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
      }

      .icon {
        margin-right: 12px;

        img {
          width: 28px;
        }
      }
    }

    .user_profile {
      width: 256px;
      height: 59px;
      padding: 0 16px;
      background: #F2F8FF;
      border-radius: 12px;

      &:hover .box_user_info {
        opacity: 1;
        display: block;
        animation-duration: 0.5s;
        animation-name: showEffect;
      }

      .avatar {
        width: 46px;
        height: 46px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .info_name {
        p.name {
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;
          color: #1F1F1F;
          margin-bottom: 0;
        }

        p.manage {
          margin: 0;
          top: -5px;
          height: 28px;
          position: relative;
          font-weight: 500;
          font-size: 12px;
          line-height: 28px;
          color: #5E6E78;
        }
      }

      .box_user_info {
        top: 60px;
        right: -5px;
        opacity: 0;
        display: none;
        position: absolute;
        z-index: 100;
        width: 155px;
        height: 75px;
        background: #FFFFFF;
        transition: 0.5s;
        padding: 8px 0 0;
        box-shadow: 0px 0px 15px rgba(12, 47, 76, 0.15);

        &::before {
          border-width: 9px;
          border-style: solid;
          border-color: transparent transparent rgb(255, 255, 255);
          border-image: initial;
          margin-left: 45px;
          bottom: 100%;
          left: 50%;
          content: "";
          height: 0px;
          width: 0px;
          position: absolute;
          pointer-events: none;
        }

        a {
          width: 100%;
          height: 30px;
          font-weight: 400;
          font-size: 15px;
          line-height: 21px;
          color: #29AB56;
          padding: 0 14px;
          text-decoration: none;
          display: flex;
          align-items: center;

          &:hover {
            color: #29AB56;
            background: #F0F0F0;
          }
        }
      }

    }

    .notice {
      #dropdownNotice {
        position: relative;
        padding: 0;
        border: none;
        background: transparent;

        i {
          color: #12151A;
        }

        .count_unRead {
          top: -5px;
          right: -5px;
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: red;
          color: white;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

  }

}


@keyframes showEffect {
  from {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: block;
  }
}

@media screen and (max-width: 1600px) {
  .header-top {
    height: 80px;
  }
}