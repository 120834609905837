#modal-detail-request-manufacturing-supplies {
  .modal-dialog {
    max-width: 1400px;

    .eibatch-record {
      padding: 20px 120px 50px;

      .eibatch-record-header-content {
        display: flex;
        margin: 20px 0;

        p {
          line-height: 15px;
        }
      }

      .note-record-eibatch {
        background-color: rgba(250, 250, 250, 1);
      }

      .eibatch-record-header-title {
        display: flex;
        justify-content: space-between;

        &-name {
          font-size: 32px;
        }

        .status-done {
          height: 28px;
          line-height: 28px;
          border-radius: 3px;
          margin-left: 16px;
          padding: 0 16px;
          background-color: #F8FFEA;
          color: rgba(0, 149, 61, 1);
        }

        .status-pendding {
          height: 28px;
          line-height: 28px;
          border-radius: 3px;
          margin-left: 16px;
          padding: 0 16px;
          background-color: #EFEFEF;
          color: rgba(116, 116, 116, 1);
        }

        .status-cancel {
          height: 28px;
          line-height: 28px;
          border-radius: 3px;
          margin-left: 16px;
          padding: 0 16px;
          background-color: #FFF9FA;
          color: rgba(255, 48, 48, 1);
        }

        .bt-action-print {
          border: none;
          outline: none;
          background: transparent;
        }
      }
    }
  }
}

.bt-action-EI-Batch {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;

  .bt-action-cancel {
    width: 150px;
    height: 30px;
    border: none;
    outline: none;
    color: black;
    border-radius: 2px;
    padding: 6px 24px 6px 16px;
    background-color: rgba(249, 249, 249, 1);
    margin-right: 10px;

    &:hover {
      background-color: rgb(227, 225, 225);
    }
  }

  .bt-action-accept {
    width: 150px;
    height: 30px;
    border: none;
    outline: none;
    color: white;
    border-radius: 2px;
    padding: 6px 24px 6px 16px;
    background-color: rgba(0, 149, 61, 1);

    &:hover {
      background-color: rgb(0, 110, 47);
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .eibatch-record * {
    visibility: visible;
  }
  .bt-action-EI-Batch {
    display: none !important;
  }

  .eibatch-record {
    position: absolute;
    left: 20px;
    width: 100%;
    height: 100%;
  }
}


