#cancel-export-batch {
  .modal-dialog {
    max-width: 620px !important;
  }

  .cancel-ei-inform {
    padding: 0 30px;

    &__title {
      width: 138px;
    }

    &__item {
      width: 200px
    }
  }
}

.btn-cancel {
  background-color: #F9F9F9;
  font-weight: 400;
  padding: 12px 74px;
  font-size: 16px;
  line-height: 140%;

  &:hover {
    background-color: #d3cfcf;
  }
}

.modal .modal-footer {
  padding: 24px 28px !important;
}

.btn-confirm-cancel-export {
  background-color: #E60A32;
  color: white;
  padding: 12px 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  &:hover {
    background-color: #bd0023;
  }
}

.fw-600 {
  font-weight: 600;
}

.infor-cancel-export {
  display: flex;

  p {
    line-height: 15px;
  }
}