.view-detail-eibatch {
  padding: 0 6px;
}

.statement_approved {
  color: rgba(0, 149, 61, 1);
}

.statement_reject {
  color: rgba(230, 10, 50, 1);
}

.statement_pendding {
  color: rgba(116, 116, 116, 1);
}


.type_export {
  color: rgba(246, 147, 32, 1);
}

.type_import {
  color: rgba(0, 149, 61, 1);
}

.bt-action-accept {
  border: none;
  outline: none;
  background: transparent;
  margin-top: 5px;

  &:disabled {
    background: transparent;
  }
}

.bt-action-accept:focus {
  border: none;
  outline: none;
  background: transparent;
}

