
.input-helper:before {
  border: 1px solid #333333 !important;
  border-radius: 4px;
}

.input-helper:after {
  background: #0f6ab9 !important;
}


.signUp_main {
  width: 100%;
  overflow: hidden;

  .back_ {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    border-bottom: 0.5px solid #DBDEE5;

    i {
      margin-bottom: 14px;
      font-size: 26px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      text-align: justify;
      margin-bottom: 0;
      color: #333333;
    }
  }

  .step_1 {
    .error {
      margin-bottom: 0;
    }

    .back_ {
      padding-bottom: 31px;
      margin-bottom: 22px;
    }
  }

  .step_2 {
    .back_ {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .form-group.h-auto {
      height: 90px !important;
    }
  }


  .form_signUp {
    width: 735px;

    .logo_login {
      margin-bottom: 70px;
    }

    h2 {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 24px;
      line-height: 48px;
      color: #000000;
    }

    label {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    .bt-signUp {
      width: 300px;
      border: none;
      height: 50px;
      background: #29AB56;
      border-radius: 2px;
      font-weight: 700;
      font-size: 16px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin-top: 48px;
    }

    .btn-back-confirm-acc {
      outline: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      padding: 0 !important;
    }

  }

  .confirm_account_container {
    display: flex;
    flex-direction: column;
    width: 555px;

    .logo_login {
      margin-bottom: 70px;
    }

    h2 {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      color: #000000;
    }

    p {
      color: #5F6368;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    label {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    .bt-signUp {
      width: 263px;
      height: 50px;
      background: white;
      border-radius: 5px;

      border: 1px solid #29AB56;
      font-weight: 700;
      font-size: 16px;
      text-transform: capitalize;
      color: #29AB56;
      margin-top: 48px;

      &:hover {
        background: #efeaea;

      }
    }

    .bt-reload-signup {
      width: 263px;
      border: none;
      height: 50px;
      background: #29AB56;
      border-radius: 5px;
      font-weight: 700;
      font-size: 16px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin-top: 48px;

      &:hover {
        background: #0f7e35;

      }
    }
  }

  .background-signup-container {
    position: relative;
  }

  .download_container {
    position: absolute;
    bottom: 30px;
    right: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_logo {
      width: 60px;
      height: 60px;
      background-color: white;
      border-radius: 12px;
    }
  }

  .download_container h2 {
    color: white;
    font-size: 36px;
    line-height: 47px;
    font-weight: 700;
    text-align: center;
  }

  .download_container p {
    color: white;
    font-size: 15.0586px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
  }


}

.preservePage .table thead th {
  vertical-align: middle;
  border-bottom: none !important;

  span {
    font-weight: 400;
  }
;
}