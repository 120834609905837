#sidebar {
  width: 280px;
  height: 100vh;
  background: #F4FFF9;
  position: relative;

  .sidebar-main {
    left: 0;
    top: 0;
    width: 280px;
    height: 100vh;
    background: #F4FFF9;
    position: fixed;
    z-index: 1010;

    .logo {
      width: 100%;
      height: 168px;
      display: inline-block;

      img {
        width: 100%;
        height: 168px;
      }
    }

    ul {
      width: 100%;
      display: inline-block !important;
      height: calc(100% - 168px);
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #c8c8c8;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #aeaeae;
      }

      li {
        display: inline-block;
        width: 100%;
        min-height: 68px;
        background: #F4FFF9;
        position: relative;
        border-bottom: 1px solid rgba(232, 234, 236, 0.78);

        .line {
          width: 4px;
          height: 100%;
          top: 0;
          right: 0;
          position: absolute;
          display: none;
          border-radius: 0px 100px 100px 0px;
          background-color: #00953D;
        }

        a {
          width: 100%;
          min-height: 68px;
          display: flex;
          align-items: center;
          padding: 0 32px;
          text-decoration: none;

          span {
            color: #000000;
            font-size: 16px;
            line-height: 21px;
            font-family: Roboto-Regular;
          }

          .icon {
            width: 26px;
            margin-right: 12px;
          }
        }
      }

      .sub_menu {
        width: 100%;
        min-height: 68px;
        border-bottom: 1px solid rgba(232, 234, 236, 0.78);

        .nav-link {
          width: 100%;
          height: 68px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(232, 234, 236, 0.78);

          &:last-child {
            border-bottom: none
          }
        }

        .ml {
          padding-left: 38px;
        }

        i {
          top: 22px;
          right: 12px;
        }

        .collapse {
          .nav-link.active {
            background: #CFFFE5;
            font-weight: 700;

            span {
              color: #00953D;
            }
          }
        }
      }

      .sub_menu.active {
        i {
          color: #00953D;
        }

        .menu-title {
          color: #00953D;
          font-family: Roboto;
        }
      }

      .nav-item.active {
        background: #CFFFE5;

        .line {
          display: block;
        }

        a span {
          color: #00953D;
          font-family: Roboto;
        }
      }
    }

    ul.nav_bottom {
      left: 0;
      bottom: 40px;
      position: absolute;
      z-index: 1010;

      li:first-child {
        border-top: 1px solid rgba(232, 234, 236, 0.78);
      }
    }

    .filter_search_map {
      padding: 0 10px;

      .filter_land_plan {
        width: 100%;
        margin-bottom: 14px;

        label {
          color: #000000;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 3px;
        }

        select {
          width: 100%;
          height: 54px;
          border: 1px solid #7C7B7B;
          border-radius: 2px;
        }
      }

      .search_map_land {
        width: 100%;
        border: 1px solid #D7D7D7;

        input {
          width: 100%;
          height: 54px;
          background-color: transparent;
          box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.15);
          color: #5C5C5C;
          font-size: 14px;
          border: none;
          border-radius: 3px;
          padding: 0 10px 0 38px;
        }

        i.fa-search {
          top: 19px;
          left: 12px;
          font-size: 18px;
        }
      }

      .list_land {
        width: 100%;
        height: 500px;
        overflow-y: scroll;
        background: #F5F5F7;
        border-radius: 8px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .land {
          li {
            width: 100%;
            height: 54px;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            padding: 0 16px;
            border: 1.5px solid rgba(232, 234, 236, 0.78);
          }

          li.active {
            background: #CFFFE5;
            font-weight: 700;
            color: #00953D;
          }
        }
      }
    }
  }

}



@media screen and (max-width: 1600px) {
  #sidebar {
    width: 260px;

    .sidebar-main {
      width: 260px;

      ul.nav {
        li {
          min-height: 60px;

          a {
            min-height: 60px;
            padding: 0 26px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  #sidebar {
    width: 250px;

    .sidebar-main {
      width: 250px;
    }
  }
}