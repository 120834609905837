.max-width-1008 {
  max-width: 1008px !important;

  .import-surplus-select {
    width: 260px;
    margin-right: 10px;
  }
}

.icon-delete-product {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

#surplus-product {
  .table-responsive {
    max-height: 360px;
    overflow-y: auto;
  }
}

.input-quatity-product {
  input.form-control, select.form-control {
    height: 33px !important;
    font-size: 14px !important;
    text-align: center;
  }
}

.button-import-surplus {
  width: 130px;
  height: 40px;
  border: none;
  background: #29AB56;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;

    background: #21984A;
  }
}

.button-close-export-batch {
  width: 130px;
  height: 40px;
  border: none;
  background: #F9F9F9;
  border-radius: 2px;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #181818;
  text-decoration: none;

  &:hover {
    color: #181818;

    background: #d9dcdc;
  }
}

.icon_add_item_export_batch {
  color: rgba(246, 147, 32, 1);
}

.button-add-batch {
  margin-top: 20px;
}