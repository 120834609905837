.icon-action-preserve {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.preserve_state_block {
  color: rgba(230, 10, 50, 1);
}

.preserve_state_processing {
  color: rgba(210, 136, 0, 1);
}

.preserve_state_pack {
  color: rgba(210, 136, 0, 1);
}

.preserve_state_preserve {
  color: rgba(0, 149, 61, 1);
}

.preserve_state_preparation {
  color: rgba(195, 195, 195, 1);
}

.view-detail-preserve {
  padding: 0 6px;
}