
#add_update_role {
  .list_permission{
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #6b6a6a;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #6b6a6a;
    }

  }
  .role_ground {
    width: 30%;
    height: 100%;
    min-height: 270px;
    padding: 0 15px 10px;
    background: #F8F8F8;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 5%;
    &:nth-child(3n){
      margin-right: 0;
    }
    .title{
      width: 100%;
      height: 44px;
      margin-bottom: 16px;
      border-bottom: 1px solid #C6C6C6;
      h4{
        font-weight: 600;
        font-size: 16px;
        font-family: Roboto;
      }
      .form-check{
        width: 130px;
      }
    }
    .form-check{
      .form-check-input{
        width: 24px;
        height: 24px;
      }
      .form-check-label{
        padding-left: 34px;
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        color: #111111;
      }
    }
  }
}