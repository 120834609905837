$color-confirm-unlock: rgba(0, 148, 68, 1);
$color-confirm-lock: rgba(230, 10, 50, 1);
#popup_confirm_lock {
  .modal-dialog {
    width: 615px;
  }

  .text-content-confirm {
    padding: 0 90px;
  }

  .btn-confirm-lock {
    background-color: $color-confirm-lock;
    color: white;
    height: 47px;

    &:hover {
      background-color: rgb(189, 4, 38);
    }
  }

  .btn-confirm-unlock {
    background-color: $color-confirm-unlock;
    color: white;
    height: 47px;

    &:hover {
      background-color: rgb(3, 107, 51);
    }
  }
}