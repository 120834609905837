.add-plan-person-in-charge-container {
  width: auto;
  margin: 0 37px 37px;

  .add-plan-person-in-charge {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #29AB56;
    margin-bottom: 37px;

  }
}

