.add-plan-quantity-container {
  width: auto;
  margin: 0 37px 37px;

  .add-plan-quantity {
    display: flex;
    padding: 20px 10px 10px;
    border: 1px solid #29AB56;
    margin-bottom: 27px;

    p {
      line-height: 14px;
    }
  }

  .modal-footer {
    padding: 0 !important;
  }

  .table-responsive {
    max-height: 360px;
    overflow-y: auto;
  }

  .table-add-plan-quantity {
    input {
      width: 100%;
      height: 38px !important;
      text-align: center;
      padding: 0 12px;

      .form-control:focus {
        border-color: transparent !important;
      }
    }
  }
}






